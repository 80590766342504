import { v4 } from 'uuid';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function dateToDTString(date: Date): string {
    const month = (date.getMonth()+1 < 10 ? "0" : "") + (date.getMonth()+1);
    const day = (date.getDate() < 10 ? "0" : "") + date.getDate();
    const hour = (date.getUTCHours() < 10 ? "0" : "") + date.getUTCHours();
    const minute = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    const second = (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();
    return "" + date.getFullYear() + month + day + "T" + hour + minute + second;
}

export function prettifyDate(date: Date): string {
    const time = `${date.getUTCHours()%12}:${(date.getUTCMinutes() < 10 ? "0" : "") + date.getUTCMinutes()} ${date.getUTCHours() >= 12 ? 'PM' : 'AM'}`
    return `${DAYS[date.getUTCDay()]}, ${MONTHS[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${time === "5:00 AM" ? "" : ` - ${time}`}`;
}

export function createEvent(summary: string, description: string, location: string, url: string, date: string): string {
    const stampDate = new Date();
    const startDate = new Date(date);
    const endDate = new Date(date);
    endDate.setHours(startDate.getHours()+3)
    endDate.setMinutes(startDate.getMinutes()+30)
    const event = [
        'BEGIN:VCALENDAR',
        'PRODID:-//PhillyMtGCube',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'UID:'+v4(),
        'DTSTAMP:'+dateToDTString(stampDate),
    ]

    if (startDate.getUTCHours() == 5) {
        startDate.setHours(startDate.getHours()-5)
        const endDay = new Date(startDate)
        endDay.setDate(startDate.getDate() + 2)
        event.push( 'DTSTART:'+dateToDTString(startDate), 'DTEND:'+dateToDTString(endDay) )
    } else {
        event.push( 'DTSTART:'+dateToDTString(startDate), 'DTEND:'+dateToDTString(endDate) )
    } 

    event.push(...[
        'SUMMARY:'+summary,
        'URL:'+url,
        'DESCRIPTION:'+description,
        'LOCATION:'+location,
        'END:VEVENT',
        'END:VCALENDAR\n'
    ])

    return event.join('\n');
}